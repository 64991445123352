import React, { useState, useEffect } from 'react'
import { storage } from '../../firebase'

export default function Delete(props) {
	const [textInput, setTextInput] = useState(null)
	const [deleteOK, setDeleteOK] = useState(false)
	const [deleteFail, setDeleteFail] = useState(false)

	const onSubmit = () => {
		try {
			const input = textInput
			const storageRef = storage.ref()
			const fileRef = storageRef.child(input)
			fileRef.delete().then(
				setDeleteOK(true)
			)
		} catch (err) {
			setDeleteFail(true)
			console.log(err)
		}
	}

	const onChange = e => {
		setDeleteOK(false)
		const input = e.target.value
		setTextInput(input)
	}

	const disabledButton = !props.items.includes(textInput) ? true : false

	return (
		<div className='py-3'>
			<h3>Delete File</h3>
			<div className='input-group'>
				<select className='custom-select' onChange={onChange}>
					<option defaultValue='Select file to delete...'>Select file to delete...</option>
					{props.items.map(item => (
						<option value={item} key={item}>{item}</option>
					))}
				</select>
				<div className="input-group-append">
					{disabledButton ? <button className="btn btn-secondary" disabled > Delete file</button> : <button className="btn bg-primary-cl" onClick={onSubmit}> Delete file</button>}
				</div>
			</div>
			{deleteOK &&
				<div className="mt-3 alert alert-success alert-dismissible">
					<strong>{textInput}</strong> has been deleted.
  				<button type="button" className="close" data-dismiss="alert" onClick={() => { setDeleteOK(false) }} aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			}
			{deleteFail &&
				<div className="mt-3 alert alert-danger alert-dismissible">
					Oops... something went wrong, please try again.
  				<button type="button" className="close" data-dismiss="alert" onClick={() => { setDeleteFail(false) }} aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			}
		</div>
	)
}