import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import '../stylesheets/Index.sass'
import Admin from './components/admin/Admin'
import Home from './components/Home'
import Archive from './components/Archive'

export default function App() {
	return (
		<Router>
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/archive' exact component={Archive} />
				<Route path='/admin' exact component={Admin} />
			</Switch>
		</Router>
	)
}