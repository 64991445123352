import React, { useState, useEffect } from 'react'
import { storage } from '../../firebase'

export default function Upload(props) {
	const [uploadFile, setUploadFile] = useState(null)
	const [uploadOK, setUploadOK] = useState(false)
	const [uploadFail, setUploadFail] = useState(false)


	const onSubmit = () => {
		try {
			const file = uploadFile
			const storageRef = storage.ref()
			const fileRef = storageRef.child(file.name)
			fileRef.put(file).then(
				setUploadOK(true)
			)
		} catch (err) {
			setUploadFail(true)
		}
	}

	const onChange = e => {
		setUploadOK(null)
		const file = e.target.files[0]
		setUploadFile(file)
	}

	return (
		<div className='py-3'>
			<h3>Upload File</h3>
			<div className="input-group">
				<div className="custom-file">
					<input type="file" className="custom-file-input" onChange={onChange} />
					<label className="custom-file-label">{uploadFile ? uploadFile.name : 'Choose file...'}</label>
				</div>
				<div className="input-group-append ml-3">
					{uploadFile ? <button className='rounded btn bg-primary-cl' onClick={onSubmit}>Upload File</button> : <button className='rounded btn btn-secondary' disabled>Upload File</button>}
				</div>
			</div>
			{uploadOK &&
				<div className="mt-3 alert alert-success alert-dismissible">
					<strong>{uploadFile.name}</strong> has been uploaded.
  				<button type="button" className="close" data-dismiss="alert" onClick={() => { setUploadOK(false) }} aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			}
			{uploadFail &&
				<div className="mt-3 alert alert-danger alert-dismissible">
					Oops... something went wrong, please try again.
  				<button type="button" className="close" data-dismiss="alert" onClick={() => { setUploadFail(false) }} aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			}
		</div>
	)
}