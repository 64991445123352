import React from 'react'
import moment from 'moment'

export default function Home(props) {
	return (
		<div className='py-3'>
			<h3>Current files on database:</h3>
			<ul className='archive-docs'>
				{
					props.items.map(item =>
						<li key={item.date}>
							<a className='doc color-primary' href={item.url} target='_blank'>{moment(item.date, 'DD MMMM YYYY').format('ddd, Do MMMM YYYY')}</a>
						</li>
					)
				}
			</ul>
		</div>
	)
}