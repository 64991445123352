import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Document, Page } from 'react-pdf/dist/entry.parcel';
import { storage } from '../firebase'
import moment from 'moment'
import '../../stylesheets/Home.sass'
import logo from '../../assets/eelanaduLogo.png'

export default function Home() {
  const storageRef = storage.ref()
  const [docDetails, setDocDetails] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)

  const fetchFiles = async () => {
    const files = []
    const fetchUrl = await storageRef.listAll()
    fetchUrl.items.map(i => (files.push(i.name)))

    const urlList = []
    for (const i of files) {
      urlList.push({ date: moment(i, 'DD-MM-YYYY').format('DD MMMM YYYY'), name: i, url: null })
    }

    const orderedFiles = urlList.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })

    const weeklyPapers = orderedFiles.reverse().slice(0, 8)
    for (const paper of weeklyPapers) {
      await storageRef.child(paper.name).getDownloadURL().then(
        response => {
          paper.url = response
        }
      )
    }
    setDocDetails(weeklyPapers)
    setPreviewUrl(orderedFiles[0].url)
  }

  useEffect(() => {
    fetchFiles()
  }, [])

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true
  }

  function renderWeeklyPapers() {
    return (
      <div className='mx-2'>
        <div>
          <h2 className='heading h5'>Read last week's papers</h2>
          <div className='input-group'>
            <select className='custom-select' onChange={(e) => { setPreviewUrl(e.target.value) }}>
              {docDetails.map(item => (
                <option value={item.url} key={item.date}>{moment(item.date, 'DD MMMM YYYY').format('ddd, Do MMMM YYYY')}</option>
              ))}
            </select>
          </div>
          <a href={previewUrl} target='_blank' className='btn bg-primary-cl mt-4'>Download newspaper</a>
        </div>
        <div className='mt-5'>
          <h2 className='heading h5'>Need an older paper?</h2>
          <p className='m-0'>Take a look at our newspaper archive to find the one you need.</p>
          <Link to={{ pathname: '/archive' }} className='btn bg-primary-cl mt-4'>View archive</Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='header mb-5'>
        <p className='heading text-white'>{moment().format('dddd, Do MMMM YYYY')}</p>
        <img className='eelanadu-logo' src={logo} />
      </div>
      {docDetails ?
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 my-5'>
              <h2 className='heading h4 pb-4 text-center'>Read the latest newspaper</h2>
              <div className='issue-preview'>
                <a href={previewUrl} target='_blank'>
                  <Document className='mx-4' file={{ url: previewUrl }} options={options}>
                    <Page className='shadow' width={window.innerWidth < 768 ? '250' : '400'} pageNumber={1} />
                  </Document>
                </a>
              </div>
            </div>
            <div className='col-md-12 col-lg-6 my-5 older-issues border rounded p-5'>
              {renderWeeklyPapers()}
            </div>
          </div>
        </div>
        : <div className='text-center heading my-5'>Loading newspapers...</div>
      }
    </div>
  )
}