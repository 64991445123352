import React, { useState, useEffect } from 'react'
import { storage } from '../firebase'
import moment from 'moment'
import '../../stylesheets/Archive'

export default function Archive() {
  const storageRef = storage.ref()
  const [archivePapers, setArchivePapers] = useState(null)

  const fetchArchiveFiles = async () => {
    const files = []
    const fetchUrl = await storageRef.listAll()
    fetchUrl.items.map(i => (files.push(i.name)))

    const urlList = []
    for (const i of files) {
      urlList.push({ date: moment(i, 'DD-MM-YYYY').format('DD MMMM YYYY'), name: i, url: null })
    }

    const orderedFiles = urlList.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })

    const weeklyPapers = orderedFiles.reverse()
    for (const paper of weeklyPapers) {
      await storageRef.child(paper.name).getDownloadURL().then(
        response => {
          paper.url = response
        }
      )
    }
    setArchivePapers(weeklyPapers)
  }

  useEffect(() => {
    fetchArchiveFiles()
  }, [])

  return (
    <div>
      <div className='page-header mb-5'>
        <p className='h1 archives-heading text-white'>Newspaper archive</p>
      </div>
      <ul className='container my-5 current-docs'>
        {
          archivePapers ?
            archivePapers.map(item =>
              <li key={item.date}>
                <a className='doc color-primary' href={item.url} target='_blank'>{moment(item.date, 'DD MMMM YYYY').format('ddd, Do MMMM YYYY')}</a>
              </li>
            )
            : <div className='text-center heading my-5'>Loading newspapers...</div>
        }
      </ul>
    </div>
  )
}