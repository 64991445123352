import React, { useState, useEffect } from "react";
import { auth, storage } from "../../firebase";
import moment from "moment";
import Home from "./Home";
import Upload from "./Upload";
import Delete from "./Delete";
import "../../../stylesheets/Admin.sass";

export default function Admin() {
  const storageRef = storage.ref();
  const [documents, setDocuments] = useState([]);
  const [docDetails, setDocDetails] = useState([]);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [userAccepted, setUserAccepted] = useState(false);
  const [userError, setUserError] = useState(false);

  const authenticateUser = (e, email, password) => {
    e.preventDefault();
    const promise = auth.signInWithEmailAndPassword(email, password);
    promise.then(() => {
      setUserAccepted(true);
    });
    promise.catch(() => {
      setUserError(true);
    });
  };

  const signUserOut = () => {
    auth.signOut().then(() => {
      setUserAccepted(false);
    });
  };

  const fetchFiles = async () => {
    const fetchUrl = await storageRef.listAll();
    const files = fetchUrl.items.map((i) => i.name);
    setDocuments(files);

    let urlList = [];
    for (let i of files) {
      const url = await storageRef.child(i).getDownloadURL();
      i = i.substring(0, i.length - 4);
      urlList.push({
        date: moment(i, "DD-MM-YYYY").format("DD MMMM YYYY"),
        url: url,
      });
    }

    let orderedFiles = urlList.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    orderedFiles.reverse();
    setDocDetails(orderedFiles);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  if (userAccepted) {
    return (
      <div className="admin-panel container mt-5">
        <div>
          <h1 className="color-primary">
            Admin Portal
            <span
              onClick={() => {
                signUserOut();
              }}
              className="ml-2 user-pill"
            >
              Log out
            </span>
          </h1>
          <p className="text-secondary font-weight-bold">
            Please refresh the page after uploading or deleting files to see new
            changes.
          </p>
        </div>
        <Upload />
        <Delete items={documents} />
        <Home items={docDetails} />
      </div>
    );
  }

  return (
    <div className="auth-form">
      <form className="bg-white shadow p-5 rounded">
        {userError && (
          <div className="mt-3 alert alert-danger alert-dismissible">
            Email address or password is incorrect, please try again.
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              onClick={() => {
                setUserError(false);
              }}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <div className="mb-5">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="login-btn btn bg-primary-cl"
          onClick={(e) => {
            authenticateUser(e, email, password);
          }}
        >
          Log in
        </button>
      </form>
    </div>
  );
}
